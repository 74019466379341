<template>
  <v-container fill-height fluid>
    <div>
    <v-layout align-center justify-center>
      <v-row align="center" justify="center" style="width: 100vw;">
        <v-col cols="8" xl="2" lg="3" md="4" sm="6" >
          <v-card>
            <v-toolbar color="darkgrey" >
              <v-toolbar-title>Login</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon v-on:click="$emit('close')">
                <v-icon>{{ icons.mdiClose }}</v-icon>
              </v-btn>
            </v-toolbar>
            <v-form v-on:submit.prevent="submitForm">
              <v-card-text>
                <v-text-field
                  id="username"
                  v-model="username"
                  :prepend-icon="icons.mdiAccount"
                  autofocus
                  label="Benutzername"
                  name="username"
                  type="text"
                  >
                </v-text-field>

                <v-text-field
                    id="password"
                    v-model="password"
                    :prepend-icon="icons.mdiLock"
                    label="Password"
                    name="password"
                    type="password"
                >
                </v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-container>
                  <v-row>
                    <p id="errorTextField" ref="errorTextField" style="color: red; text-align: left;">
                      {{ errorMessage }}
                    </p>
                  </v-row>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn color="green" type="submit">Login</v-btn>
                  </v-row>
                </v-container>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-layout>
    </div>
  </v-container>
</template>


<script>
import {mdiLock, mdiClose, mdiAccount} from '@mdi/js'
import {mapActions} from "vuex";
// eslint-disable-next-line no-unused-vars
import router from "../../router/index.js"

export default {
  name: "LoginForm",
  data: () => ({
    icons: {
      mdiLock,
      mdiClose,
      mdiAccount
    },
    password: null,
    username: null,
    errorMessage: "",
  }),
  methods: {
    ...mapActions(["LogIn"]),
    submitForm: async function() {
      const user = JSON.stringify({password: this.password, username: this.username })
      try {
        await this.LogIn(user);
        this.$emit('loggedIn');
      } catch (error) {
        console.log(error);
      }
    },
  }
}
</script>

<style scoped>

</style>
